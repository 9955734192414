<!--
 * @Author: awenyjw
 * @Date: 2023-07-07 10:38:14
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-07-07 14:52:54
 * @FilePath: \hc-smartSupervision\src\views\system\tenant\menuTemplate.vue
 * @Description: 
 * 
-->
<template>
  <div>
    <a-spin :spinning="formLoading">
      <a-tree
        v-model="checkedKeys"
        multiple
        checkable
        :tree-data="menuTreeData"
        :selected-keys="selectedKeys"
        :replaceFields="replaceFields"
        @expand="onExpand"
        @select="onSelect"
        @check="treeCheck"
      >
      </a-tree>
    </a-spin>
  </div>
</template>

<script>
import { SysMenuTreeForGrant } from '@/api/modular/system/menuManage'
import { sysTenantOwnMenuApplication } from '@/api/modular/system/tenantManage'
import { sysRoleOwnMenuApplication } from '@/api/modular/system/tenantManage' //更改为公司拥有菜单接口

export default {
  name: 'menuTemplate',
  props: {
    tenantEntity: {
      type: Object,
      default: {}
    },
    app: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checkedKeys: [],
      menuTreeData: [],
      selectedKeys: [],
      replaceFields: {
        key: 'id'
      },
      expandedKeys: [],
      formLoading: true
    }
  },
  created() {},
  mounted() {
    // this.tenantMenu(this.tenantEntity, this.app)
    // this.initTreeData1(this.tenantEntity)
  },
  methods: {
    getCheckedKeys() {
      this.$emit('childReturnValue', this.checkedKeys)
    },

    // initTreeData1(row) {

    //   console.log(row,"==initTreeData1==");
      
    //   sysRoleOwnMenuApplication({
    //     type: 1,
    //     id: row.roleId
    //   }).then(res => {
    //     if (res.success) {
    //       console.log(res, '=========sysRoleOwnMenuApplication======')
    //     }
    //   })
    // },

    // 初始化方法
    tenantMenu(record, app) {
      //   this.tenantEntity = record
      this.getMenuTree(app)
    },
    treeCheck(checkKeys, event) {
      this.halfCheckedKeys = event.halfCheckedKeys
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },
    getMenuTree(app) {
      let params = {
        // Application:this.radioApp,
        Application: app
      }
      SysMenuTreeForGrant(params).then(res => {
        if (res.success) {
          this.menuTreeData = res.data
          this.expandedMenuKeys(this.tenantEntity)
          // 默认展开目录级
          this.menuTreeData.forEach(item => {
            this.expandedKeys.push(item.id)
          })
        }
      })
    },
    /**
     * 此应用已有菜单权限
     */
    expandedMenuKeys(record) {
      sysRoleOwnMenuApplication({
        type:1,
        id: record.id
      }).then(res => {
        if (res.success) {
          let allMenus = res.data
          if (allMenus.length) {
            this.checkedKeys = allMenus
              .filter(obj => obj && obj.application && obj.application === this.app)
              .map(obj => obj.menuIds)[0]
              .map(item => item.id)

            if (this.checkedKeys) {
              this.findAllChildren(this.menuTreeData)
            }
          }
        }
        this.formLoading = false
      })
    },
    // 遍历树形然后获取到对父节点进行移除，使用子节点，而且将父节点加入到mainMenuList
    findAllChildren(data) {
      data.forEach((item, index) => {
        if (item.children.length !== 0) {
          for (let i = 0; i < this.checkedKeys.length; i++) {
            if (item.id === this.checkedKeys[i]) {
              this.checkedKeys.splice(i, 1)
            }
          }
          this.findAllChildren(item.children)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
