/*
 * @Author: awenyjw
 * @Date: 2023-05-06 09:39:18
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-05-06 14:51:32
 * @FilePath: \hc-smartSupervision\src\api\modular\supervision\projectMag\tab3.js
 * @Description: 
 * 
 */

import {
    axios
} from '@/utils/request'
/**
 * @description: 分页查询
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-06 10:17:21
 */
export function _pagePosition(params) {
    return axios({
        url: '/sysRole/positionpage',
        method: 'get',
        params: params
    })
}
/**
 * @description: 新增岗位
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-06 10:08:52
 */
export function _addPosition(params) {
    return axios({
        url: '/sysRole/positionadd',
        method: 'post',
        data: params,  
    })
}

/**
 * @description: 删除岗位
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-06 10:09:02
 */
export function _deletePosition(params) {
    return axios({
        url: '/sysRole/positiondelete',
        method: 'post',
        data: params
    })
}
/**
 * @description: 编辑岗位
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-06 10:10:38
 */
export function _editPosition(params) {
    return axios({
        url: '/sysRole/positionedit',
        method: 'post',
        data: params
    })
}

/**
 * @description: 拉取权限树
 * @param {*} appcode
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-06 14:06:58
 */
export function _proTree(params) {
    return axios({
        url: '/project/treeforprojectgrant',
        method: 'get',
        params: params
    })
}
/**
 * @description: 授权岗位应用
 * @param {*} params
 * @return {*}
 * @Author: awenyjw
 * @Date: 2023-05-06 14:51:09
 */
export function _postGrantMenu(params) {
    return axios({
        url: '/sysRole/positiongrantmenu',
        method: 'post',
        data: params
    })
}



